<template>
  <div id="page-user-list">

    <div class="flex flex-wrap items-center">


      <!-- ITEMS PER PAGE -->
      <div class="flex-grow">


          <vx-card title="เลือกวันเวลา">
        <div class="vx-row">
          <div class="vx-col w-full sm:w-1/3 md:w-1/3 lg:w-1/3 xl:w-1/3" >
           <label class="vs-input--label">เลือกเดือนที่ต้องการดูรายการย้อนหลัง</label>
            <!-- <v-select  v-model="month" :options="monthOptions" name="status"
              :dir="$vs.rtl ? 'rtl' : 'ltr'" /> -->
                <vs-select  class="w-full mt-4" v-model="month" >
                <vs-select-item  :key="index" :value="item.value" :text="item.text" v-for="(item,index) in monthOptions" />
        </vs-select>
            <br>
          </div>
           <div class="vx-col w-full sm:w-1/3 md:w-1/3 lg:w-1/3 xl:w-1/3" >
            <label class="vs-input--label">เลือกปีที่ต้องการดูรายการย้อนหลัง</label>
            <!-- <v-select  v-model="year" :options="yearOptions" name="status"
              :dir="$vs.rtl ? 'rtl' : 'ltr'" /> -->
           <vs-select  class="w-full mt-4" v-model="year" >
                <vs-select-item  :key="index" :value="item.value" :text="item.text" v-for="(item,index) in yearOptions" />
        </vs-select>
            <br>
           </div>
           <div class="vx-col w-full sm:w-1/3 md:w-1/3 lg:w-1/3 xl:w-1/3" >
           <br>
           </div>
        </div>
         <vs-button  color="primary" @click="selectdate_time ()">ค้นหา</vs-button>
          </vx-card>
        <br>

        <div class="vx-row">

          <div class="vx-col w-full sm:w-1/3 md:w-1/3 lg:w-1/3 xl:w-1/3">
            <statistics-card-line hideChart class="mb-base" icon="PlusCircleIcon" icon-right :statistic="sum_deposit"
              statisticTitle="ยอดฝาก" color="warning" />
          </div>

          <div class="vx-col w-full sm:w-1/3 md:w-1/3 lg:w-1/3 xl:w-1/3">
            <statistics-card-line hideChart class="mb-base" icon="MinusCircleIcon" icon-right :statistic="sum_withdraw"
              statisticTitle="ยอดถอน" color="danger" />
          </div>
           <div class="vx-col w-full sm:w-1/3 md:w-1/3 lg:w-1/3 xl:w-1/3">
            <statistics-card-line hideChart class="mb-base" icon="DollarSignIcon" icon-right :statistic="sum_profit"
              statisticTitle="กำไร" color="success" />
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col w-full sm:w-1/4 md:w-1/4 lg:w-1/4 xl:w-1/4">
            <statistics-card-line hideChart class="mb-base" icon="DollarSignIcon" icon-right :statistic="sum_deposit_active"
              statisticTitle="ยอดฝาก/คน" color="success" />
          </div>
           <div class="vx-col w-full sm:w-1/4 md:w-1/4 lg:w-1/4 xl:w-1/4">
            <statistics-card-line hideChart class="mb-base" icon="DollarSignIcon" icon-right :statistic="sumsagame"
              statisticTitle="SA Game" color="warning" />
          </div>

          <div class="vx-col w-full sm:w-1/4 md:w-1/4 lg:w-1/4 xl:w-1/4">
            <statistics-card-line hideChart class="mb-base" icon="DollarSignIcon" icon-right :statistic="sumsexy"
              statisticTitle="Sexy Game" color="danger" />
          </div>
          <div class="vx-col w-full sm:w-1/4 md:w-1/4 lg:w-1/4 xl:w-1/4">
            <statistics-card-line hideChart class="mb-base" icon="DollarSignIcon" icon-right :statistic="sumsbobet"
              statisticTitle="SBOBET" color="success" />
          </div>


        </div>
          <div class="vx-row">
            <div class="vx-col w-full sm:w-1/4 md:w-1/4 lg:w-1/4 xl:w-1/4">
            <statistics-card-line hideChart class="mb-base" icon="DollarSignIcon" icon-right :statistic="sumkmgame"
              statisticTitle="KINGMAKER GAME " color="success" />
          </div>
           <div class="vx-col w-full sm:w-1/4 md:w-1/4 lg:w-1/4 xl:w-1/4">
            <statistics-card-line hideChart class="mb-base" icon="DollarSignIcon" icon-right :statistic="sumjokerslot"
              statisticTitle="JOKER SLOT" color="success" />
          </div>
           <div class="vx-col w-full sm:w-1/4 md:w-1/4 lg:w-1/4 xl:w-1/4">
            <statistics-card-line hideChart class="mb-base" icon="DollarSignIcon" icon-right :statistic="sumambgame"
              statisticTitle="AMB POKER " color="success" />
          </div>
           <div class="vx-col w-full sm:w-1/4 md:w-1/4 lg:w-1/4 xl:w-1/4">
            <statistics-card-line hideChart class="mb-base" icon="DollarSignIcon" icon-right :statistic="sumaggame"
              statisticTitle="ASIA GAMING " color="success" />
          </div>
        </div>

        <div class="vx-row">
           <div class="vx-col w-full sm:w-1/4 md:w-1/4 lg:w-1/4 xl:w-1/4">
            <statistics-card-line hideChart class="mb-base" icon="DollarSignIcon" icon-right :statistic="sumallbetgame"
              statisticTitle="ALLBET GAME" color="success" />
          </div>
           <div class="vx-col w-full sm:w-1/4 md:w-1/4 lg:w-1/4 xl:w-1/4">
            <statistics-card-line hideChart class="mb-base" icon="DollarSignIcon" icon-right :statistic="sumdragongame"
              statisticTitle="DRAGON GAME " color="success" />
          </div>
          <div class="vx-col w-full sm:w-1/4 md:w-1/4 lg:w-1/4 xl:w-1/4">
            <statistics-card-line hideChart class="mb-base" icon="DollarSignIcon" icon-right :statistic="sumrchgame"
              statisticTitle="RICH88 GAME " color="success" />
          </div>
           <div class="vx-col w-full sm:w-1/4 md:w-1/4 lg:w-1/4 xl:w-1/4">
            <statistics-card-line hideChart class="mb-base" icon="DollarSignIcon" icon-right :statistic="sumhggame"
              statisticTitle="HOTGRAPH GAME " color="success" />
          </div>
        </div>


         <div class="vx-row">

           <div class="vx-col w-full sm:w-1/4 md:w-1/4 lg:w-1/4 xl:w-1/4">
            <statistics-card-line hideChart class="mb-base" icon="DollarSignIcon" icon-right :statistic="sumpgslot"
              statisticTitle="PG SLOT" color="success" />
          </div>
           <div class="vx-col w-full sm:w-1/4 md:w-1/4 lg:w-1/4 xl:w-1/4">
            <statistics-card-line hideChart class="mb-base" icon="DollarSignIcon" icon-right :statistic="sumgssslot"
              statisticTitle="GSS SLOT " color="success" />
          </div>
         <div class="vx-col w-full sm:w-1/4 md:w-1/4 lg:w-1/4 xl:w-1/4">
            <statistics-card-line hideChart class="mb-base" icon="DollarSignIcon" icon-right :statistic="sumsppslot"
              statisticTitle="SimplePlay SLOT " color="success" />
          </div>
            <div class="vx-col w-full sm:w-1/4 md:w-1/4 lg:w-1/4 xl:w-1/4">
            <statistics-card-line hideChart class="mb-base" icon="DollarSignIcon" icon-right :statistic="sumppslot"
              statisticTitle="PP SLOT " color="success" />
          </div>

        </div>
         <div class="vx-row">
          <div class="vx-col w-full sm:w-1/4 md:w-1/4 lg:w-1/4 xl:w-1/4">
            <statistics-card-line hideChart class="mb-base" icon="DollarSignIcon" icon-right :statistic="sumxingame"
              statisticTitle="XIN SLOT" color="success" />
          </div>
           <div class="vx-col w-full sm:w-1/4 md:w-1/4 lg:w-1/4 xl:w-1/4">
            <statistics-card-line hideChart class="mb-base" icon="DollarSignIcon" icon-right :statistic="summggame"
              statisticTitle="MG GAME" color="success" />
          </div>
          <div class="vx-col w-full sm:w-1/4 md:w-1/4 lg:w-1/4 xl:w-1/4">
            <statistics-card-line hideChart class="mb-base" icon="DollarSignIcon" icon-right :statistic="sumdggame"
              statisticTitle="DG GAME" color="success" />
          </div>
         <div class="vx-col w-full sm:w-1/4 md:w-1/4 lg:w-1/4 xl:w-1/4">
            <statistics-card-line hideChart class="mb-base" icon="DollarSignIcon" icon-right :statistic="sumevogame"
              statisticTitle="EVO GAME " color="success" />
          </div>
          <div class="vx-col w-full sm:w-1/4 md:w-1/4 lg:w-1/4 xl:w-1/4">
            <statistics-card-line hideChart class="mb-base" icon="DollarSignIcon" icon-right :statistic="sumwmgame"
              statisticTitle="WM GAME " color="success" />
          </div>
           <div class="vx-col w-full sm:w-1/4 md:w-1/4 lg:w-1/4 xl:w-1/4">
            <statistics-card-line hideChart class="mb-base" icon="DollarSignIcon" icon-right :statistic="sumhdggame"
              statisticTitle="HUAY DRAGON " color="success" />
          </div>
          <div class="vx-col w-full sm:w-1/4 md:w-1/4 lg:w-1/4 xl:w-1/4">
            <statistics-card-line hideChart class="mb-base" icon="DollarSignIcon" icon-right :statistic="sumtfggame"
              statisticTitle="TF GAMING " color="success" />
          </div>
          <div class="vx-col w-full sm:w-1/4 md:w-1/4 lg:w-1/4 xl:w-1/4">
            <statistics-card-line hideChart class="mb-base" icon="DollarSignIcon" icon-right :statistic="sumspggame"
              statisticTitle="SPADE GAMING " color="success" />
          </div>
           <div class="vx-col w-full sm:w-1/4 md:w-1/4 lg:w-1/4 xl:w-1/4">
            <statistics-card-line hideChart class="mb-base" icon="DollarSignIcon" icon-right :statistic="sumdgsgame"
              statisticTitle="DRAGOONSOFT GAMING " color="success" />
          </div>
          <div class="vx-col w-full sm:w-1/4 md:w-1/4 lg:w-1/4 xl:w-1/4">
            <statistics-card-line hideChart class="mb-base" icon="DollarSignIcon" icon-right :statistic="sumuggame"
              statisticTitle="UNITED GAMING " color="success" />
          </div>
          <div class="vx-col w-full sm:w-1/4 md:w-1/4 lg:w-1/4 xl:w-1/4">
            <statistics-card-line hideChart class="mb-base" icon="DollarSignIcon" icon-right :statistic="sumgcgame"
              statisticTitle="G-CLUB GAMING " color="success" />
          </div>
          <div class="vx-col w-full sm:w-1/4 md:w-1/4 lg:w-1/4 xl:w-1/4">
            <statistics-card-line hideChart class="mb-base" icon="DollarSignIcon" icon-right :statistic="sumjergame"
              statisticTitle="JERRY GAMING " color="success" />
          </div>
          <div class="vx-col w-full sm:w-1/4 md:w-1/4 lg:w-1/4 xl:w-1/4">
            <statistics-card-line hideChart class="mb-base" icon="DollarSignIcon" icon-right :statistic="sumllkgame"
              statisticTitle="LALIKA SPORT " color="success" />
          </div>
        </div>

        <vx-card title="ตารางสรุปยอดรายเดือน" v-if="table_data_all.length!==0">
          <h6 class="text-danger mb-4"> * ยอดอัพเดททุก 4 ชั่วโมง (อัพเดทล่าสุด : {{ lastupdate }})</h6>
          <div class="export-table">
            <vs-table  stripe :data="table_data_all">

              <template slot="thead">

                <vs-th>วันที่</vs-th>
                <vs-th> Deposit</vs-th>
                <vs-th> Withdraw </vs-th>
                <!-- <vs-th> SA Game</vs-th>
                <vs-th> Sexy Game </vs-th>
                <vs-th> JOKER SLOT </vs-th>
                <vs-th> PG SLOT </vs-th>
                <vs-th> KM GAME </vs-th>
                <vs-th> WM GAME </vs-th>
                <vs-th> AMB POKER </vs-th>
                <vs-th>SBOBET </vs-th>
                <vs-th>GSS SLOT </vs-th>
                <vs-th>SimplePlay SLOT </vs-th>
                <vs-th>EVO GAME </vs-th> -->
                <vs-th> Profit </vs-th>
                <vs-th> Total Balance </vs-th>
                <vs-th></vs-th>

              </template>

              <template slot-scope="{data}">

                <vs-tr :key="indextr" v-for="(tr, indextr) in data">

                  <vs-td>{{  data[indextr]['date'] }}</vs-td>
                  <vs-td>{{  currency(data[indextr]['sumdeposit']) }}</vs-td>
                  <vs-td>{{  currency(data[indextr]['sumwithdraw']) }}</vs-td>
                  <vs-td class="text-success" v-if="data[indextr]['sumprofit']>0">{{ currency(data[indextr]['sumprofit']) }}</vs-td>
                  <vs-td class="text-danger" v-else>{{ currency(data[indextr]['sumprofit']) }}</vs-td>
                  <vs-td>{{  currency(data[indextr]['sumbalance']) }}</vs-td>
                  <vs-button  class="mt-3 mx-1 mb-3" size="medium" color="#00a4e4" @click="winlosegame(indextr)" >win-lose GAME </vs-button>
                  <vs-button  class="mt-3 mx-1 mb-3" size="medium" color="#00a4e4" @click="winlosegameactive(indextr)" >Active GAME </vs-button>
                </vs-tr>

              </template>

            </vs-table>
        <vs-popup classContent="popup-example" title="WIN-LOSS GAME " :active.sync="popupgame">
          <vs-table  stripe :data="table_data_all">
                <vs-tr>
                  <vs-th>วันที่</vs-th>
                  <vs-td>{{  table_data_all[index]['date'] }}</vs-td>
                </vs-tr>
                <vs-tr>
                   <vs-th> SA Game</vs-th>
                  <vs-td class="text-success" v-if="table_data_all[index]['sum_sagame']>0">{{  currency(table_data_all[index]['sum_sagame']) }}</vs-td>
                  <vs-td class="text-danger" v-else>{{  currency(table_data_all[index]['sum_sagame']) }}</vs-td>
                </vs-tr>
                <vs-tr>
                <vs-th> Sexy Game</vs-th>
                  <vs-td class="text-success" v-if="table_data_all[index]['sum_sexygame']>0">{{  currency(table_data_all[index]['sum_sexygame']) }}</vs-td>
                  <vs-td class="text-danger" v-else>{{  currency(table_data_all[index]['sum_sexygame']) }}</vs-td>
                </vs-tr>
                <vs-tr>
                  <vs-th> JOKER SLOT </vs-th>
                  <vs-td class="text-success" v-if="table_data_all[index]['sum_joker']>0">{{  currency(table_data_all[index]['sum_joker']) }}</vs-td>
                  <vs-td class="text-danger" v-else>{{  currency(table_data_all[index]['sum_joker']) }}</vs-td>
                </vs-tr>
                <vs-tr>
                    <vs-th> PG SLOT </vs-th>
                  <vs-td class="text-success" v-if="table_data_all[index]['sum_pg']>0">{{  currency(table_data_all[index]['sum_pg']) }}</vs-td>
                  <vs-td class="text-danger" v-else>{{  currency(table_data_all[index]['sum_pg']) }}</vs-td>
                </vs-tr>
                <vs-tr>
                  <vs-th> KM GAME </vs-th>
                  <vs-td class="text-success" v-if="table_data_all[index]['sum_kmgame']>0">{{  currency(table_data_all[index]['sum_kmgame']) }}</vs-td>
                  <vs-td class="text-danger" v-else>{{  currency(table_data_all[index]['sum_kmgame']) }}</vs-td>
                </vs-tr>
                <vs-tr>
                  <vs-th> WM GAME </vs-th>
                  <vs-td class="text-success" v-if="table_data_all[index]['sum_wmgame']>0">{{  currency(table_data_all[index]['sum_wmgame']) }}</vs-td>
                  <vs-td class="text-danger" v-else>{{  currency(table_data_all[index]['sum_wmgame']) }}</vs-td>
                </vs-tr>
                <vs-tr>
                  <vs-th> AMB POKER </vs-th>
                  <vs-td class="text-success" v-if="table_data_all[index]['sum_amb']>0">{{  currency(table_data_all[index]['sum_amb']) }}</vs-td>
                  <vs-td class="text-danger" v-else>{{  currency(table_data_all[index]['sum_amb']) }}</vs-td>
               </vs-tr>
                <vs-tr>
                  <vs-th>SBOBET </vs-th>
                  <vs-td class="text-success" v-if="table_data_all[index]['sum_sbo']>0">{{  currency(table_data_all[index]['sum_sbo']) }}</vs-td>
                  <vs-td class="text-danger" v-else>{{  currency(table_data_all[index]['sum_sbo']) }}</vs-td>
                </vs-tr>
                <vs-tr>
                  <vs-th>GSS SLOT </vs-th>
                  <vs-td class="text-success" v-if="table_data_all[index]['sum_gss']>0">{{  currency(table_data_all[index]['sum_gss']) }}</vs-td>
                  <vs-td class="text-danger" v-else>{{  currency(table_data_all[index]['sum_gss']) }}</vs-td>
                </vs-tr>
                <vs-tr>
                  <vs-th>SimplePlay SLOT </vs-th>
                  <vs-td class="text-success" v-if="table_data_all[index]['sum_spp']>0">{{  currency(table_data_all[index]['sum_spp']) }}</vs-td>
                  <vs-td class="text-danger" v-else>{{  currency(table_data_all[index]['sum_spp']) }}</vs-td>
                </vs-tr>
                <vs-tr>
                  <vs-th>EVO GAME </vs-th>
                  <vs-td class="text-success" v-if="table_data_all[index]['sum_evo']>0">{{  currency(table_data_all[index]['sum_evo']) }}</vs-td>
                  <vs-td class="text-danger" v-else>{{  currency(table_data_all[index]['sum_evo']) }}</vs-td>
                </vs-tr>
                <vs-tr>
                  <vs-th>DG GAME </vs-th>
                  <vs-td class="text-success" v-if="table_data_all[index]['sum_dg']>0">{{  currency(table_data_all[index]['sum_dg']) }}</vs-td>
                  <vs-td class="text-danger" v-else>{{  currency(table_data_all[index]['sum_dg']) }}</vs-td>
                </vs-tr>
                 <vs-tr>
                  <vs-th>MG GAME </vs-th>
                  <vs-td class="text-success" v-if="table_data_all[index]['sum_mg']>0">{{  currency(table_data_all[index]['sum_mg']) }}</vs-td>
                  <vs-td class="text-danger" v-else>{{  currency(table_data_all[index]['sum_mg']) }}</vs-td>
                </vs-tr>
                <vs-tr>
                  <vs-th>PP GAME </vs-th>
                  <vs-td class="text-success" v-if="table_data_all[index]['sum_pp']>0">{{  currency(table_data_all[index]['sum_pp']) }}</vs-td>
                  <vs-td class="text-danger" v-else>{{  currency(table_data_all[index]['sum_pp']) }}</vs-td>
                </vs-tr>
                <vs-tr>
                  <vs-th>RICH88 GAME </vs-th>
                  <vs-td class="text-success" v-if="table_data_all[index]['sum_rch']>0">{{  currency(table_data_all[index]['sum_rch']) }}</vs-td>
                  <vs-td class="text-danger" v-else>{{  currency(table_data_all[index]['sum_rch']) }}</vs-td>
                </vs-tr>
                 <vs-tr>
                  <vs-th>DRAGON GAME </vs-th>
                  <vs-td class="text-success" v-if="table_data_all[index]['sum_dragon']>0">{{  currency(table_data_all[index]['sum_dragon']) }}</vs-td>
                  <vs-td class="text-danger" v-else>{{  currency(table_data_all[index]['sum_dragon']) }}</vs-td>
                </vs-tr>
                 <vs-tr>
                  <vs-th>ALLBET GAME </vs-th>
                  <vs-td class="text-success" v-if="table_data_all[index]['sum_allbet']>0">{{  currency(table_data_all[index]['sum_allbet']) }}</vs-td>
                  <vs-td class="text-danger" v-else>{{  currency(table_data_all[index]['sum_allbet']) }}</vs-td>
                </vs-tr>
                <vs-tr>
                  <vs-th>HOTGRAPH  </vs-th>
                  <vs-td class="text-success" v-if="table_data_all[index]['sum_hg']>0">{{  currency(table_data_all[index]['sum_hg']) }}</vs-td>
                  <vs-td class="text-danger" v-else>{{  currency(table_data_all[index]['sum_hg']) }}</vs-td>
                </vs-tr>
                 <vs-tr>
                  <vs-th>ASIA GAMING  </vs-th>
                  <vs-td class="text-success" v-if="table_data_all[index]['sum_ag']>0">{{  currency(table_data_all[index]['sum_ag']) }}</vs-td>
                  <vs-td class="text-danger" v-else>{{  currency(table_data_all[index]['sum_ag']) }}</vs-td>
                </vs-tr>
                <vs-tr>
                  <vs-th>XIN SLOT  </vs-th>
                  <vs-td class="text-success" v-if="table_data_all[index]['sum_xin']>0">{{  currency(table_data_all[index]['sum_xin']) }}</vs-td>
                  <vs-td class="text-danger" v-else>{{  currency(table_data_all[index]['sum_xin']) }}</vs-td>
                </vs-tr>
                <vs-tr>
                  <vs-th>HUAY DRAGON  </vs-th>
                  <vs-td class="text-success" v-if="table_data_all[index]['sum_hdg']>0">{{  currency(table_data_all[index]['sum_hdg']) }}</vs-td>
                  <vs-td class="text-danger" v-else>{{  currency(table_data_all[index]['sum_hdg']) }}</vs-td>
                </vs-tr>
                <vs-tr>
                  <vs-th>TF GAMING  </vs-th>
                  <vs-td class="text-success" v-if="table_data_all[index]['sum_tfg']>0">{{  currency(table_data_all[index]['sum_tfg']) }}</vs-td>
                  <vs-td class="text-danger" v-else>{{  currency(table_data_all[index]['sum_tfg']) }}</vs-td>
                </vs-tr>
                <vs-tr>
                  <vs-th>SPADE GAMING  </vs-th>
                  <vs-td class="text-success" v-if="table_data_all[index]['sum_spg']>0">{{  currency(table_data_all[index]['sum_spg']) }}</vs-td>
                  <vs-td class="text-danger" v-else>{{  currency(table_data_all[index]['sum_spg']) }}</vs-td>
                </vs-tr>
                <vs-tr>
                  <vs-th>DRAGOONSOFT GAMING  </vs-th>
                  <vs-td class="text-success" v-if="table_data_all[index]['sum_dgs']>0">{{  currency(table_data_all[index]['sum_dgs']) }}</vs-td>
                  <vs-td class="text-danger" v-else>{{  currency(table_data_all[index]['sum_dgs']) }}</vs-td>
                </vs-tr>
                <vs-tr>
                  <vs-th>UNITED GAMING  </vs-th>
                  <vs-td class="text-success" v-if="table_data_all[index]['sum_ug']>0">{{  currency(table_data_all[index]['sum_ug']) }}</vs-td>
                  <vs-td class="text-danger" v-else>{{  currency(table_data_all[index]['sum_ug']) }}</vs-td>
                </vs-tr>
                <vs-tr>
                  <vs-th>G-CLUB GAMING  </vs-th>
                  <vs-td class="text-success" v-if="table_data_all[index]['sum_gc']>0">{{  currency(table_data_all[index]['sum_gc']) }}</vs-td>
                  <vs-td class="text-danger" v-else>{{  currency(table_data_all[index]['sum_gc']) }}</vs-td>
                </vs-tr>
                <vs-tr>
                  <vs-th>JERRY GAMING  </vs-th>
                  <vs-td class="text-success" v-if="table_data_all[index]['sum_jer']>0">{{  currency(table_data_all[index]['sum_jer']) }}</vs-td>
                  <vs-td class="text-danger" v-else>{{  currency(table_data_all[index]['sum_jer']) }}</vs-td>
                </vs-tr>
                <vs-tr>
                  <vs-th>LALIKA GAMING  </vs-th>
                  <vs-td class="text-success" v-if="table_data_all[index]['sum_llk']>0">{{  currency(table_data_all[index]['sum_llk']) }}</vs-td>
                  <vs-td class="text-danger" v-else>{{  currency(table_data_all[index]['sum_llk']) }}</vs-td>
                </vs-tr>
               <vs-tr>
                  <vs-th  class="text-success"> รวม  </vs-th>
                  <vs-td class="text-success" v-if="table_data_all[index]['sum_all']>0">{{  currency(table_data_all[index]['sum_all']) }}</vs-td>
                  <vs-td class="text-danger" v-else>{{  currency(table_data_all[index]['sum_all']) }}</vs-td>
                </vs-tr>
            </vs-table>
      </vs-popup>

      <vs-popup classContent="popup-example" title="ACTIVE GAME " :active.sync="popupgameactive">
   <vs-table v-if="table_data_active.length > 0" stripe :data="table_data_all_active">
                <vs-tr>
                  <vs-th>วันที่</vs-th>
                  <vs-td>{{  table_data_all_active[index2]['date'] }}</vs-td>
                </vs-tr>
                <vs-tr>
                   <vs-th> รายการฝาก/คน</vs-th>
                  <vs-td class="text-success" v-if="table_data_all_active[index2]['sumdeposit']>0">{{  currencyActive(table_data_all_active[index2]['sumdeposit']) }}</vs-td>
                  <vs-td class="text-danger" v-else>{{  currencyActive(table_data_all_active[index2]['sumdeposit']) }}</vs-td>
                </vs-tr>
                <vs-tr>
                   <vs-th> SA Game</vs-th>
                  <vs-td class="text-success" v-if="table_data_all_active[index2]['sum_sagame']>0">{{  currencyActive(table_data_all_active[index2]['sum_sagame']) }}</vs-td>
                  <vs-td class="text-danger" v-else>{{  currencyActive(table_data_all_active[index2]['sum_sagame']) }}</vs-td>
                </vs-tr>
                <vs-tr>
                <vs-th> Sexy Game</vs-th>
                  <vs-td class="text-success" v-if="table_data_all_active[index2]['sum_sexygame']>0">{{  currencyActive(table_data_all_active[index2]['sum_sexygame']) }}</vs-td>
                  <vs-td class="text-danger" v-else>{{  currencyActive(table_data_all_active[index2]['sum_sexygame']) }}</vs-td>
                </vs-tr>
                <vs-tr>
                  <vs-th> JOKER SLOT </vs-th>
                  <vs-td class="text-success" v-if="table_data_all_active[index2]['sum_joker']>0">{{  currencyActive(table_data_all_active[index2]['sum_joker']) }}</vs-td>
                  <vs-td class="text-danger" v-else>{{  currencyActive(table_data_all_active[index2]['sum_joker']) }}</vs-td>
                </vs-tr>
                <vs-tr>
                    <vs-th> PG SLOT </vs-th>
                  <vs-td class="text-success" v-if="table_data_all_active[index2]['sum_pg']>0">{{  currencyActive(table_data_all_active[index2]['sum_pg']) }}</vs-td>
                  <vs-td class="text-danger" v-else>{{  currencyActive(table_data_all_active[index2]['sum_pg']) }}</vs-td>
                </vs-tr>
                <vs-tr>
                  <vs-th> KM GAME </vs-th>
                  <vs-td class="text-success" v-if="table_data_all_active[index2]['sum_kmgame']>0">{{  currencyActive(table_data_all_active[index2]['sum_kmgame']) }}</vs-td>
                  <vs-td class="text-danger" v-else>{{  currencyActive(table_data_all_active[index2]['sum_kmgame']) }}</vs-td>
                </vs-tr>
                <vs-tr>
                  <vs-th> WM GAME </vs-th>
                  <vs-td class="text-success" v-if="table_data_all_active[index2]['sum_wmgame']>0">{{  currencyActive(table_data_all_active[index2]['sum_wmgame']) }}</vs-td>
                  <vs-td class="text-danger" v-else>{{  currencyActive(table_data_all_active[index2]['sum_wmgame']) }}</vs-td>
                </vs-tr>
                <vs-tr>
                  <vs-th> AMB POKER </vs-th>
                  <vs-td class="text-success" v-if="table_data_all_active[index2]['sum_amb']>0">{{  currencyActive(table_data_all_active[index2]['sum_amb']) }}</vs-td>
                  <vs-td class="text-danger" v-else>{{  currencyActive(table_data_all_active[index2]['sum_amb']) }}</vs-td>
               </vs-tr>
                <vs-tr>
                  <vs-th>SBOBET </vs-th>
                  <vs-td class="text-success" v-if="table_data_all_active[index2]['sum_sbo']>0">{{  currencyActive(table_data_all_active[index2]['sum_sbo']) }}</vs-td>
                  <vs-td class="text-danger" v-else>{{  currencyActive(table_data_all_active[index2]['sum_sbo']) }}</vs-td>
                </vs-tr>
                <vs-tr>
                  <vs-th>GSS SLOT </vs-th>
                  <vs-td class="text-success" v-if="table_data_all_active[index2]['sum_gss']>0">{{  currencyActive(table_data_all_active[index2]['sum_gss']) }}</vs-td>
                  <vs-td class="text-danger" v-else>{{  currencyActive(table_data_all_active[index2]['sum_gss']) }}</vs-td>
                </vs-tr>
                <vs-tr>
                  <vs-th>SimplePlay SLOT </vs-th>
                  <vs-td class="text-success" v-if="table_data_all_active[index2]['sum_spp']>0">{{  currencyActive(table_data_all_active[index2]['sum_spp']) }}</vs-td>
                  <vs-td class="text-danger" v-else>{{  currencyActive(table_data_all_active[index2]['sum_spp']) }}</vs-td>
                </vs-tr>
                <vs-tr>
                  <vs-th>EVO GAME </vs-th>
                  <vs-td class="text-success" v-if="table_data_all_active[index2]['sum_evo']>0">{{  currencyActive(table_data_all_active[index2]['sum_evo']) }}</vs-td>
                  <vs-td class="text-danger" v-else>{{  currencyActive(table_data_all_active[index2]['sum_evo']) }}</vs-td>
                </vs-tr>
                <vs-tr>
                  <vs-th>DG GAME </vs-th>
                  <vs-td class="text-success" v-if="table_data_all_active[index2]['sum_dg']>0">{{  currencyActive(table_data_all_active[index2]['sum_dg']) }}</vs-td>
                  <vs-td class="text-danger" v-else>{{  currencyActive(table_data_all_active[index2]['sum_dg']) }}</vs-td>
                </vs-tr>
                 <vs-tr>
                  <vs-th>MG GAME </vs-th>
                  <vs-td class="text-success" v-if="table_data_all_active[index2]['sum_mg']>0">{{  currencyActive(table_data_all_active[index2]['sum_mg']) }}</vs-td>
                  <vs-td class="text-danger" v-else>{{  currencyActive(table_data_all_active[index2]['sum_mg']) }}</vs-td>
                </vs-tr>
                <vs-tr>
                  <vs-th>PP GAME </vs-th>
                  <vs-td class="text-success" v-if="table_data_all_active[index2]['sum_pp']>0">{{  currencyActive(table_data_all_active[index2]['sum_pp']) }}</vs-td>
                  <vs-td class="text-danger" v-else>{{  currencyActive(table_data_all_active[index2]['sum_pp']) }}</vs-td>
                </vs-tr>
                <vs-tr>
                  <vs-th>RICH88 GAME </vs-th>
                  <vs-td class="text-success" v-if="table_data_all_active[index2]['sum_rch']>0">{{  currencyActive(table_data_all_active[index2]['sum_rch']) }}</vs-td>
                  <vs-td class="text-danger" v-else>{{  currencyActive(table_data_all_active[index2]['sum_rch']) }}</vs-td>
                </vs-tr>
                 <vs-tr>
                  <vs-th>DRAGON GAME </vs-th>
                  <vs-td class="text-success" v-if="table_data_all_active[index2]['sum_dragon']>0">{{  currencyActive(table_data_all_active[index2]['sum_dragon']) }}</vs-td>
                  <vs-td class="text-danger" v-else>{{  currencyActive(table_data_all_active[index2]['sum_dragon']) }}</vs-td>
                </vs-tr>
                 <vs-tr>
                  <vs-th>ALLBET GAME </vs-th>
                  <vs-td class="text-success" v-if="table_data_all_active[index2]['sum_allbet']>0">{{  currencyActive(table_data_all_active[index2]['sum_allbet']) }}</vs-td>
                  <vs-td class="text-danger" v-else>{{  currencyActive(table_data_all_active[index2]['sum_allbet']) }}</vs-td>
                </vs-tr>
                <vs-tr>
                  <vs-th>HOTGRAPH  </vs-th>
                  <vs-td class="text-success" v-if="table_data_all_active[index2]['sum_hg']>0">{{  currencyActive(table_data_all_active[index2]['sum_hg']) }}</vs-td>
                  <vs-td class="text-danger" v-else>{{  currencyActive(table_data_all_active[index2]['sum_hg']) }}</vs-td>
                </vs-tr>
                 <vs-tr>
                  <vs-th>ASIA GAMING  </vs-th>
                  <vs-td class="text-success" v-if="table_data_all_active[index2]['sum_ag']>0">{{  currencyActive(table_data_all_active[index2]['sum_ag']) }}</vs-td>
                  <vs-td class="text-danger" v-else>{{  currencyActive(table_data_all_active[index2]['sum_ag']) }}</vs-td>
                </vs-tr>
                <vs-tr>
                  <vs-th>XIN SLOT  </vs-th>
                  <vs-td class="text-success" v-if="table_data_all_active[index2]['sum_xin']>0">{{  currencyActive(table_data_all_active[index2]['sum_xin']) }}</vs-td>
                  <vs-td class="text-danger" v-else>{{  currencyActive(table_data_all_active[index2]['sum_xin']) }}</vs-td>
                </vs-tr>
                <vs-tr>
                  <vs-th>HUAY DRAGON  </vs-th>
                  <vs-td class="text-success" v-if="table_data_all_active[index2]['sum_hdg']>0">{{  currencyActive(table_data_all_active[index2]['sum_hdg']) }}</vs-td>
                  <vs-td class="text-danger" v-else>{{  currencyActive(table_data_all_active[index2]['sum_hdg']) }}</vs-td>
                </vs-tr>
                <vs-tr>
                  <vs-th>TF GAMING  </vs-th>
                  <vs-td class="text-success" v-if="table_data_all_active[index2]['sum_tfg']>0">{{  currencyActive(table_data_all_active[index2]['sum_tfg']) }}</vs-td>
                  <vs-td class="text-danger" v-else>{{  currencyActive(table_data_all_active[index2]['sum_tfg']) }}</vs-td>
                </vs-tr>
                <vs-tr>
                  <vs-th>SPADE GAMING  </vs-th>
                  <vs-td class="text-success" v-if="table_data_all_active[index2]['sum_spg']>0">{{  currencyActive(table_data_all_active[index2]['sum_spg']) }}</vs-td>
                  <vs-td class="text-danger" v-else>{{  currencyActive(table_data_all_active[index2]['sum_spg']) }}</vs-td>
                </vs-tr>
                <vs-tr>
                  <vs-th>DRAGOONSOFT GAMING  </vs-th>
                  <vs-td class="text-success" v-if="table_data_all_active[index2]['sum_dgs']>0">{{  currencyActive(table_data_all_active[index2]['sum_dgs']) }}</vs-td>
                  <vs-td class="text-danger" v-else>{{  currencyActive(table_data_all_active[index2]['sum_dgs']) }}</vs-td>
                </vs-tr>
                <vs-tr>
                  <vs-th>UNITED GAMING  </vs-th>
                  <vs-td class="text-success" v-if="table_data_all_active[index2]['sum_ug']>0">{{  currencyActive(table_data_all_active[index2]['sum_ug']) }}</vs-td>
                  <vs-td class="text-danger" v-else>{{  currencyActive(table_data_all_active[index2]['sum_ug']) }}</vs-td>
                </vs-tr>
                <vs-tr>
                  <vs-th>G-CLUB GAMING  </vs-th>
                  <vs-td class="text-success" v-if="table_data_all_active[index2]['sum_gc']>0">{{  currencyActive(table_data_all_active[index2]['sum_gc']) }}</vs-td>
                  <vs-td class="text-danger" v-else>{{  currencyActive(table_data_all_active[index2]['sum_gc']) }}</vs-td>
                </vs-tr>
                <vs-tr>
                  <vs-th>JERRY GAMING  </vs-th>
                  <vs-td class="text-success" v-if="table_data_all_active[index2]['sum_jer']>0">{{  currencyActive(table_data_all_active[index2]['sum_jer']) }}</vs-td>
                  <vs-td class="text-danger" v-else>{{  currencyActive(table_data_all_active[index2]['sum_jer']) }}</vs-td>
                </vs-tr>
                <vs-tr>
                  <vs-th>LALIKA SPORT  </vs-th>
                  <vs-td class="text-success" v-if="table_data_all_active[index2]['sum_llk']>0">{{  currencyActive(table_data_all_active[index2]['sum_llk']) }}</vs-td>
                  <vs-td class="text-danger" v-else>{{  currencyActive(table_data_all_active[index2]['sum_llk']) }}</vs-td>
                </vs-tr>
               <vs-tr>
                  <vs-th  class="text-success"> รวม  </vs-th>
                  <vs-td class="text-success" v-if="table_data_all_active[index2]['sum_all']>0">{{  currencyActive(table_data_all_active[index2]['sum_all']) }}</vs-td>
                  <vs-td class="text-danger" v-else>{{  currencyActive(table_data_all_active[index2]['sum_all']) }}</vs-td>
                </vs-tr>
            </vs-table>
            <h1 v-else>ไม่พบมีข้อมูล</h1>
      </vs-popup>
          </div>
        </vx-card>
        <vx-card v-else>
              <h1 >ไม่พบมีข้อมูล</h1>
        </vx-card>
      </div>
    </div>

  </div>

</template>

<script>
import StatisticsCardLine from '@/components/statistics-cards/StatisticsCardLine.vue'
import axios from '../../axios'
import vSelect from 'vue-select'
import moment from 'moment'
export default {
  components: {
    StatisticsCardLine,
    vSelect
  },
  data () {
    return {
      popupgame:false,
      popupgameactive:false,
      monthOptions: [
        {
          text: 'มกราคม',
          value: '01'
        },
        {
          text: 'กุมภาพันธ์',
          value: '02'
        },
        {
          text: 'มีนาคม',
          value: '03'
        },
        {
          text: 'เมษายน',
          value: '04'
        },
        {
          text: 'พฤษภาคม',
          value: '05'
        },
        {
          text: 'มิถุนายน',
          value: '06'
        },
        {
          text: 'กรกฎาคม',
          value: '07'
        },
        {
          text: 'สิงหาคม',
          value: '08'
        },
        {
          text: 'กันยายน',
          value: '09'
        },
        {
          text: 'ตุลาคม',
          value: '10'
        },
        {
          text: 'พฤศจิกายน',
          value: '11'
        },
        {
          text: 'ธันวาคม',
          value: '12'
        }
      ],
      yearOptions: [
        {
          text: moment.tz('Asia/Bangkok').subtract(1, 'year').format('YYYY').toString(),
          value: moment.tz('Asia/Bangkok').subtract(1, 'year').format('YYYY')
        },
        {
          text: moment.tz('Asia/Bangkok').format('YYYY'),
          value: moment.tz('Asia/Bangkok').format('YYYY')
        }
      ],
      month: moment.tz('Asia/Bangkok').format('MM'),
      year: moment.tz('Asia/Bangkok').format('YYYY'),
      table_data_all: [],
      table_data: [],
      table_data_all_active: [],
      table_data_active: [],
      sum_data: [],
      sum_deposit: 0,
      sum_deposit_active: 0,
      sum_withdraw: 0,
      sumsagame: 0,
      sumpgslot: 0,
      sumjokerslot: 0,
      sumsbobet:0,
      sumgssslot:0,
      sumsppslot:0,
      sumppslot:0,
      sumsexy: 0,
      sum_profit: 0,
      sumkmgame:0,
      sumwmgame:0,
      sumambgame:0,
      sumevogame:0,
      sumdggame:0,
      summggame:0,
      sumrchgame:0,
      sumallbetgame:0,
      sumdragongame:0,
      sumhggame:0,
      sumaggame:0,
      sumxingame:0,
      sumhdggame:0,
      sumtfggame:0,
      sumspggame:0,
      sumdgsgame:0,
      sumuggame:0,
      sumgcgame:0,
      sumjergame:0,
      sumllkgame:0,
      sumdepositactive:0,
      lastupdate: moment.tz('Asia/Bangkok').format('YYYY-MM-DD H:mm:ss'),
      index:0,
      index2:0
    }
  },
  async mounted () {
    await this.selectdate_time()
  },
  methods: {
    async winlosegame (data) {
      this.popupgame = true
      this.index = data
    },
    async winlosegameactive (data2) {
      this.popupgameactive = true
      this.index2 = data2
    },
    async  selectdate_time () {
      await this.get_data_active()
      await this.get_data()
    },
    async get_data () {
      await axios
        .get('dashboardmonth/deposit')
        .then(response => (this.sum_data = response.data))
      this.sum_deposit = this.currency(this.sum_data.sumdeposit)
      this.sum_withdraw = this.currency(this.sum_data.sumwithdraw)
      this.sum_profit = this.currency(this.sum_data.profit)
      this.sumsagame = this.currency(this.sum_data.sumsagame)
      this.sumsexy = this.currency(this.sum_data.sumsexy)

      await axios
        .get(`dashboardmonth/selectmonth/${this.month}/${this.year}`, {
        })
        .then(response => (this.table_data = response.data))
      if (this.table_data.length === 0) {
        this.table_data_all = ''
        this.sum_deposit = '0'
        this.sum_withdraw = '0'
        this.sum_profit = '0'
        this.sumsexy = '0'
        this.sumsagame = '0'
        this.sumpgslot = '0'
        this.sumjokerslot = '0'
        this.sumkmgame = 0
        this.sumwmgame = 0
        this.sumambgame = 0
        this.sumsbobet = 0
        this.sumgssslot = 0
        this.sumsppslot = 0
        this.sumppslot = 0
        this.sumevogame = 0
        this.sumdggame = 0
        this.summggame = 0
        this.sumrchgame = 0
        this.sumallbetgame = 0
        this.sumdragongame = 0
        this.sumhggame = 0
        this.sumaggame = 0
        this.sumxingame = 0
        this.sumhdggame = 0
        this.sumtfggame = 0
        this.sumspggame = 0
        this.sumdgsgame = 0
        this.sumuggame = 0
        this.sumgcgame = 0
        this.sumllkgame = 0
        this.sumjergame = 0
      } else {
        this.table_data_all = this.table_data[0].sm_all
        this.sum_deposit = this.table_data[0].sm_sum_deposit
        this.sum_withdraw = this.table_data[0].sm_sum_withdraw
        this.sum_profit = this.table_data[0].sm_sum_profit
        this.sumsexy = this.table_data[0].sm_sum_sexygame
        this.sumsagame = this.table_data[0].sm_sum_sagame
        this.sumpgslot = this.table_data[0].sm_sum_pg
        this.sumjokerslot = this.table_data[0].sm_sum_joker
        this.sumkmgame = this.table_data[0].sm_sum_kingmaker
        this.sumambgame = this.table_data[0].sm_sum_amb
        this.sumsbobet = this.table_data[0].sm_sum_sbo
        this.sumgssslot = this.table_data[0].sm_sum_gss
        this.sumsppslot = this.table_data[0].sm_sum_spp
        this.sumppslot = this.table_data[0].sm_sum_pp
        this.sumevogame = this.table_data[0].sm_sum_evo
        this.sumdggame = this.table_data[0].sm_sum_dg
        this.summggame = this.table_data[0].sm_sum_mg
        this.sumrchgame = this.table_data[0].sm_sum_rch
        this.sumallbetgame = this.table_data[0].sm_sum_allbet
        this.sumdragongame = this.table_data[0].sm_sum_dragon
        this.sumhggame = this.table_data[0].sm_sum_hg
        this.sumaggame = this.table_data[0].sm_sum_ag
        this.sumxingame = this.table_data[0].sm_sum_xin
        this.sumhdggame = this.table_data[0].sm_sum_hdg
        this.sumtfggame = this.table_data[0].sm_sum_tfg
        this.sumspggame = this.table_data[0].sm_sum_spg
        this.sumdgsgame = this.table_data[0].sm_sum_dgs
        this.sumuggame = this.table_data[0].sm_sum_ug
        this.sumgcgame = this.table_data[0].sm_sum_gc
        this.sumjergame = this.table_data[0].sm_sum_jer
        this.sumllkgame = this.table_data[0].sm_sum_llk
        this.lastupdate = moment(this.table_data[0].sm_lastupdate).tz('Asia/Bangkok').format('YYYY-MM-DD H:mm:ss')
        this.sum_deposit = this.currency(this.table_data[0].sm_sum_deposit)
        this.sum_withdraw = this.currency(this.table_data[0].sm_sum_withdraw)
        this.sum_profit = this.currency(this.table_data[0].sm_sum_profit)
        this.sumsexy = this.currency(this.table_data[0].sm_sum_sexygame)
        this.sumsagame = this.currency(this.table_data[0].sm_sum_sagame)
        this.sumpgslot = this.currency(this.table_data[0].sm_sum_pg)
        this.sumjokerslot = this.currency(this.table_data[0].sm_sum_joker)
        this.sumkmgame = this.currency(this.table_data[0].sm_sum_kingmaker)
        this.sumwmgame = this.currency(this.table_data[0].sm_sum_wm)
        this.sumambgame = this.currency(this.table_data[0].sm_sum_amb)
        this.sumsbobet = this.currency(this.table_data[0].sm_sum_sbo)
        this.sumsppslot = this.currency(this.table_data[0].sm_sum_spp)
        this.sumppslot = this.currency(this.table_data[0].sm_sum_pp)
        this.sumgssslot = this.currency(this.table_data[0].sm_sum_gss)
        this.sumevogame = this.currency(this.table_data[0].sm_sum_evo)
        this.sumdggame = this.currency(this.table_data[0].sm_sum_dg)
        this.summggame = this.currency(this.table_data[0].sm_sum_mg)
        this.sumrchgame = this.currency(this.table_data[0].sm_sum_rch)
        this.sumallbetgame = this.currency(this.table_data[0].sm_sum_allbet)
        this.sumdragongame = this.currency(this.table_data[0].sm_sum_dragon)
        this.sumhggame = this.currency(this.table_data[0].sm_sum_hg)
        this.sumaggame = this.currency(this.table_data[0].sm_sum_ag)
        this.sumxingame = this.currency(this.table_data[0].sm_sum_xin)
        this.sumhdggame = this.currency(this.table_data[0].sm_sum_hdg)
        this.sumtfggame = this.currency(this.table_data[0].sm_sum_tfg)
        this.sumspggame = this.currency(this.table_data[0].sm_sum_spg)
        this.sumdgsgame = this.currency(this.table_data[0].sm_sum_dgs)
        this.sumuggame = this.currency(this.table_data[0].sm_sum_ug)
        this.sumgcgame = this.currency(this.table_data[0].sm_sum_gc)
        this.sumjergame = this.currency(this.table_data[0].sm_sum_jer)
        this.sumllkgame = this.currency(this.table_data[0].sm_sum_llk)
      }


    },
    async get_data_active () {
      await axios
        .get(`dashboardmonth/selectmonthactive/${this.month}/${this.year}`, {
        })
        .then(response => (this.table_data_active = response.data))
      console.log(this.table_data_active)
      if (this.table_data_active.length === 0) {
        this.table_data_all_active = []
        this.sum_deposit_active = '0'
      } else {
        this.table_data_all_active = this.table_data_active[0].sm_all
        this.sum_deposit_active = this.table_data_active[0].sm_sum_deposit
        this.sum_deposit_active = this.currencyActive(this.table_data_active[0].sm_sum_deposit)
      }
    },
    currency (amount) {
      amount = (amount * 1.00).toFixed(2)
      //SET NULL OR NaN = 0
      if (!amount || amount === 'NaN') {
        amount = 0
      }
      if (amount >= 0) {
        if (typeof amount === 'number') {
          amount = String(Math.abs(amount))
        }
        return (amount).replace(/\d(?=(\d{3})+\.)/g, '$&,')
      } else {
        amount = String(amount)
        return (amount).replace(/\d(?=(\d{3})+\.)/g, '$&,')
        // amount = String(Math.abs(amount))
        // return `-${(amount).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`
      }

    },
    currencyActive (amount) {
      amount = (amount * 1.00).toFixed(0)

      // SET NULL OR NaN = 0
      if (!amount || amount === 'NaN') {
        amount = 0
      }

      if (amount >= 0) {
        if (typeof amount === 'number') {
          amount = String(Math.abs(amount))
        }
        return amount.replace(/\B(?=(\d{3})+\b)/g, ',')
      } else {
        amount = String(amount)
        return amount.replace(/\B(?=(\d{3})+\b)/g, ',')
        // amount = String(Math.abs(amount));
        // return `-${amount.replace(/\B(?=(\d{3})+\b)/g, ',')}`;
      }
    }
  }
}

</script>
